<script>
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import StatCard from "@/components/dashboard/StatCard.vue";
import API from "@/plugins/axios-method";
import UserStatisticTableCard from "@/components/dashboard/user/user-statistic-table-card.vue";
import DataLoader from '../../../components/data-loader.vue';
import ClientPagination from '../../../components/dashboard/ClientPagination.vue';
import NotFound from "@/components/not-found.vue";

export default {
  page: {
    meta: [{ name: "description", content: 'Application Dashboard' }],
  },
  components: {
    NotFound,
    StatCard,
    Layout,
    PageHeader,
    UserStatisticTableCard,
    DataLoader,
    ClientPagination
  },
  data() {
    return {
      url : '/stats/commercial/' + this.$route.params.id ,
      member_count: 0,
      total_registration_fees: 0,
      total_deposit: 0,
      users: [],
      paginatedUsers: [],
      active_user: null,
      showDataLoader: true,
      notFound: false,
      pageNumber: 1,
      itemsPerPage: 10

    };
  },
  mounted() {
    this.getStats()
    this.getUser()
  },
  methods: {
    getUser(){
      API.get('/users/'+ this.$route.params.id)
          .then(response => {
            if (response.status === 200){
              this.active_user = response.data
              if (this.active_user.supervisor){
                this.getCommercials()
              } else if(this.active_user.commercial){
                this.getMembers()
              }
            }
          })
          .catch(e => {
            if(e.response.status === 404){
              this.notFound = true
            }
            this.showDataLoader = false
          })
    },
    getMembers(){
      API.get('/users/'+ this.$route.params.id +'/members')
          .then(response => {
            if (response.status === 200){
              response.data["hydra:member"].map(user => {
                this.users.push(user)
              })
              this.paginatedUsers = this.paginateUsers(this.users, this.pageNumber, this.itemsPerPage)
              this.showDataLoader = false
            }
          })
          .catch(() => {
            this.showDataLoader = false
          })
    },
    getCommercials(){
      API.get('/users/'+ this.$route.params.id +'/commercials')
          .then(response => {
            if (response.status === 200){
              response.data["hydra:member"].map(user => {
                this.users.push(user)
              })
              this.paginatedUsers = this.paginateUsers(this.users, this.pageNumber, this.itemsPerPage)
              this.showDataLoader = false
            }
          })
          .catch(() => {
            this.showDataLoader = false
          })
    },
    getStats(){
      API.get(this.url)
          .then(response => {
            if (response.status === 200){
              const statistics = response.data
              this.member_count = statistics.users
              this.total_registration_fees = statistics.registration
              this.total_deposit = statistics.deposit

              this.showDataLoader = false
            }
          })
          .catch(e => {
            if(e.response.status === 404){
              this.notFound = true
            }
            this.showDataLoader = false
          })
    },
    // getGods(){
    //   API.get('/admin/users/'+ this.$route.params.id +'/referrals')
    //       .then(response => {
    //         if (response.status === 200){
    //           response.data["hydra:member"].map(user => {
    //             this.users.push(user)
    //           })
    //           this.paginatedUsers = this.paginateUsers(this.users, this.pageNumber, this.itemsPerPage)
    //         }
    //       })
    //       .catch(() => {
    //
    //       })
    // },
    paginateUsers(array, pageNumber, itemsPerPage = 10){
      const startIndex = (pageNumber - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      return array.slice(startIndex, endIndex);
    },
    formatPhoneNumber(phoneNumber){
        return phoneNumber?.split('237')[1]
    },
    nextPage(){
      this.setCurrentPage('next')
      this.paginatedUsers = this.paginateUsers(this.users, this.pageNumber, this.itemsPerPage)
    },
    previousPage(){
      this.setCurrentPage('previous')
      this.paginatedUsers = this.paginateUsers(this.users, this.pageNumber, this.itemsPerPage)
    },
    navigate(page){
      this.pageNumber = page
      this.paginatedUsers = this.paginateUsers(this.users, this.pageNumber, this.itemsPerPage)
    },
    setCurrentPage(direction){
      if (direction === 'next'){
        if ( this.pageNumber < (this.users.length / this.itemsPerPage) ) this.pageNumber = this.pageNumber + 1
      } else  {
        if (this.pageNumber > 1) this.pageNumber = this.pageNumber - 1
      }
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :show-add-button="false" :title="'Statistiques'" :show-search="false"  />
    <DataLoader v-if="showDataLoader"/>
    <NotFound v-if="notFound && !showDataLoader"/>
    <div v-if="!showDataLoader && !notFound">
      <section>
        <div class="row">
          <div class="col-12">
            <h5 class="mb-3 pb-1">Statistiques de {{ active_user?.fullName }} : {{ formatPhoneNumber(active_user?.phoneNumber) }}</h5>
          </div>
        </div>
        <div class="row">
          <StatCard
              :amount="total_registration_fees"
              :is-money="true"
              :is-increase="true"
              :percentage="65"
              :title="'Frais d\'Inscriptions'"
          />
          <StatCard
              :amount="total_deposit"
              :is-money="true"
              :is-increase="true"
              :percentage="65"
              :title="'Epargne Totale'"
          />
        </div>
      </section>
      <section>
          <div class="card" v-if="active_user?.supervisor">
              <div class="card-body">
                  <UserStatisticTableCard
                      :users="paginatedUsers"
                      :role="'commercial'"
                      v-if="paginateUsers.length > 0"
                  />
                  <ClientPagination
                    :next="users.length > 0"
                    :currentPage="this.pageNumber"
                    :totalItems="users.length"
                    :itemsPerPage="this.itemsPerPage"
                    @navigate="navigate"
                    @nextPage="nextPage"
                    @previousPage="previousPage"
                  />
              </div>
          </div>
        <div class="card" v-if="active_user?.commercial">
          <div class="card-body">
            <UserStatisticTableCard
                v-if="paginateUsers.length > 0"
                :users="paginatedUsers"
                :role="'member'"
            />
            <ClientPagination
                :next="users.length > 0"
                :currentPage="this.pageNumber"
                :totalItems="users.length"
                :itemsPerPage="this.itemsPerPage"
                @navigate="navigate"
                @nextPage="nextPage"
                @previousPage="previousPage"
            />
          </div>
        </div>
      </section>
    </div>
  </Layout>
</template>